import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hook";
import { s3ImageUrl } from "utils/FileUtils";

export type TarotTopicProps = {};

export default function TarotTopic(props: TarotTopicProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const isLogin = useAppSelector((root) => root.auth.isLogin);
  const topics = [
    {
      id: 1,
      title: t("love"),
      image: s3ImageUrl("tarot_love_full.png"),
      bgColor: "#F5B1AD",
      type: "love",
    },
    {
      id: 2,
      title: t("career"),
      image: s3ImageUrl("tarot_career_full.png"),
      bgColor: "#C4E9F4",
      type: "career",
    },
    {
      id: 3,
      title: t("finance"),
      image: s3ImageUrl("tarot_finance_full.png"),
      bgColor: "#FFE2BA",
      type: "finance",
    },
  ];

  const handleTopicClick = (topicType: string) => {
    const destination = isLogin ? "/tarot-question" : "/tarot-experience";
    navigate(destination, {
      state: { topicType: topicType },
    });
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-[#210909]">
      <div className="flex h-full w-full max-w-screen-md flex-col">
        <PageHeader transparent backColor="#fff" />
        <Gap size="L" />
        <div className="w-full px-10">
          <h1 className="mb-2 text-center font-inter text-[24px] font-semibold leading-[32px] text-white">
            {t("tarotTopic")}
          </h1>
          <p className="text-center font-inter text-[14px] font-normal leading-[20px] text-tertiary">
            {t("tarotTopicDescription")}
          </p>
        </div>
        <Gap size="S" />
        <div className="flex w-full flex-col items-center px-4">
          {topics.map((topic) => (
            <div
              style={{ backgroundColor: topic.bgColor }}
              key={topic.id}
              onClick={() => handleTopicClick(topic.type)}
              className="mt-4 h-auto w-full cursor-pointer overflow-hidden rounded-[16px] md:mt-10 md:w-5/6"
            >
              <img
                src={topic.image}
                alt=""
                className="h-auto w-full cursor-pointer object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
