import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { s3ImageUrl } from "utils/FileUtils";

export type TarotWelcomeProps = {};

export default function TarotWelcome(props: TarotWelcomeProps) {
  const navigate = useNavigate();
  const { t } = useLanguage();
  return (
    <div className="flex h-screen overflow-hidden flex-col items-center justify-center bg-[#210909]">
      <div className="flex h-full w-full max-w-screen-md flex-col">
        <PageHeader transparent backColor="#fff" />
        <Gap size="L" />
        <div className="px-4 pr-20">
          <h1 className="mb-4 font-space text-[48px] font-bold leading-[56px] text-white">
            {t("tarotWelcome")}
          </h1>
        </div>
      </div>
      <div className="flex w-full max-w-screen-md flex-col items-center justify-center px-4 pb-6">
        <div className="relative w-full">
          <img
            className="absolute -bottom-6 right-0 z-10 w-5/6 max-w-[400px]"
            src={s3ImageUrl("san_tarot_girl.png")}
            alt=""
          />
          <img
            className="absolute bottom-4 right-0 w-5/6 max-w-[400px]"
            src={s3ImageUrl("san_girl_bg.png")}
            alt=""
          />
          <img
            className="absolute -right-36 bottom-72 z-30 h-20"
            src={s3ImageUrl("cloud.png")}
            alt=""
          />
          <img
            className="absolute right-6 bottom-60 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
          <img
            className="absolute bottom-16 -left-36 z-30 h-20"
            src={s3ImageUrl("cloud.png")}
            alt=""
          />
          <img
            className="absolute left-6 bottom-32 z-30 h-6 w-6"
            src={s3ImageUrl("star.png")}
            alt=""
          />
        </div>
        <SanButton
          className="bg-white"
          type="outline"
          title={t("startTarot")}
          onClick={() => {
            navigate("/tarot-select-topic");
          }}
        />
        <Gap size="S" />
        <a href="/">
          <span className="text-center text-[14px] font-semibold text-white">
            {t("backToOrder")}
          </span>
        </a>
      </div>
    </div>
  );
}
