import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { useLanguage } from "hooks/useLanguage";
import { useEffect, useState } from "react";
import { s3ImageUrl } from "utils/FileUtils";

export type TarotQuestionProps = {};

export default function TarotQuestion(props: TarotQuestionProps) {
  const { t } = useLanguage();
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-[#210909] font-inter">
      <div className="flex h-full w-full max-w-screen-md flex-col items-center">
        <PageHeader transparent backColor="#fff" />
        <Gap size="L" />
        <div className="w-full px-10">
          <h1 className="mb-2 text-center font-inter text-[24px] font-semibold leading-[32px] text-white">
            {t("tarotQuestion")}
          </h1>
          <p className="px-4 text-center font-inter text-[14px] font-normal leading-[20px] text-tertiary">
            {t("questionDescription")}
          </p>
        </div>
        <Gap size="S" />
        <img
          src={s3ImageUrl("tarot_divide.png")}
          className="w-3/4 md:w-3/5"
          alt=""
        />
        <div className="h-[320px] w-[200px] px-4 pt-4">
          <input
            className="h-[160px] w-[200px] resize-none bg-transparent text-center text-14px text-white placeholder-gray-menu focus:border-none focus:outline-none focus:ring-0"
            placeholder={t("questionPlaceholder")}
          />
        </div>
        <Gap size="S" />
        <div className={`fixed inset-x-0 bottom-4 px-4 text-center text-white`}>
          <SanButton
            className="border-[1px] border-white bg-transparent"
            title={t("selectCard")}
          />
        </div>
      </div>
    </div>
  );
}
