import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import "overlayscrollbars/overlayscrollbars.css";
import "assets/styles/scrollbar.css";
import "assets/styles/rc-collapse.css";
import "assets/styles/index.css";
import SignInPage from "pages/SignInPage/SignInPage";
import { AuthProvider } from "contexts/AuthContext";
import VerifyPage from "pages/SignInPage/VerifyPage";
import MainLayout from "./MainLayout";
import OrdersPage from "./pages/OrderPage/OrderPage";
import MenuPage from "./pages/MenuPage/MenuPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import PaymentResultPage from "./pages/PaymentResultPage/PaymentResultPage";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ProductDetailPage } from "./pages/ProductDetailPage/ProductDetailPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import OrderDetailPage from "./pages/OrderDetailPage/OrderDetailPage";
import BillDetailPage from "./pages/BillDetailPage/BillDetailPage";
import DiscountSelectionPage from "./pages/DiscountSelectionPage/DiscountSelectionPage";
import LoginPage from "pages/LoginPage/LoginPage";
import OtpVerificationPage from "pages/OtpVerificationPage/OtpVerificationPage";
import FavoritePage from "pages/FavoritePage/FavoritePage";
import AboutPage from "pages/AboutPage/AboutPage";
import TarotWelcome from "pages/TarotExperience/TarotWelcome";
import TarotTopic from "pages/TarotExperience/TarotTopic";
import TarotQuestion from "pages/TarotExperience/TarotQuestion";
import TarotExperience from "pages/TarotExperience/TarotExperience";
import TarotResult from "pages/TarotExperience/TarotResult";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <div
                    className={`font-inter flex w-screen flex-row justify-center`}
                  >
                    <MainLayout />
                  </div>
                }
              >
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/orders" element={<OrdersPage />} />
              </Route>
              <Route path="/payment-result" element={<PaymentResultPage />} />
              <Route
                path="/product/:product_id"
                element={<ProductDetailPage />}
              />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/order-detail/" element={<OrderDetailPage />} />
              <Route path="/bill-detail" element={<BillDetailPage />} />
              <Route path="/discount" element={<DiscountSelectionPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/otp" element={<OtpVerificationPage />} />
              <Route path="/favorite" element={<FavoritePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/welcome-to-tarot" element={<TarotWelcome />} />
              <Route path="/tarot-select-topic" element={<TarotTopic />} />
              <Route path="/tarot-question" element={<TarotQuestion />} />
              <Route path="/tarot-experience" element={<TarotExperience />} />
              <Route path="/tarot-result" element={<TarotResult />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
