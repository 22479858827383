import { s3ImageUrl } from "utils/FileUtils";
import { useState, useEffect } from "react";
import ImageWithModal from "components/ImageWithModal";

export type TarotCardProps = {
  isActive?: boolean;
  onCardClick?: () => void;
  cardImage?: string;
  className?: string;
  imageClassName?: string;
};

export default function TarotCard({
  isActive,
  onCardClick,
  cardImage = "card4x.png",
  className = "",
  imageClassName = "",
}: TarotCardProps) {
  const [marginBottom, setMarginBottom] = useState(0);

  useEffect(() => {
    if (isActive) {
      setMarginBottom(48);
    } else {
      setMarginBottom(0);
    }
  }, [isActive]);

  return (
    <div
      className={`flex h-[200px] w-fit cursor-pointer flex-col items-center justify-center ${className}`}
    >
      <ImageWithModal
        src={s3ImageUrl(cardImage)}
        containerClassName={className}
        alt=""
        onClick={onCardClick}
        className={`h-32 w-fit rounded-[4px] object-cover transition-all duration-200 ease-in-out ${imageClassName}`}
        style={{ marginBottom }}
      />
    </div>
  );
}
