import Modal from "@mui/joy/Modal";
import { DetailedHTMLProps, ImgHTMLAttributes, useState } from "react";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
type ImageWithModalProps = {
  src: string;
  alt: string;
  className?: string;
  onClick?: () => void;
  containerClassName?: string;
};

const ImageWithModal = ({
  src,
  alt,
  className,
  containerClassName,
  onClick,
  ...props
}: ImageWithModalProps &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const [showModal, setShowModal] = useState(false);
  const onClickToShowModal = () => {
    setShowModal(true);
  };
  return (
    <div className={containerClassName} {...props}>
      <img
        onClick={onClick ? onClick : onClickToShowModal}
        src={src}
        alt={alt}
        className={className}
      />
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalDialog sx={{ p: 0, borderRadius: 32 }} variant={"plain"}>
          <ModalClose />
          <img src={src} alt={alt} className="w-[300px]" />
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default ImageWithModal;
