import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { useLanguage } from "hooks/useLanguage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hook";
import { s3ImageUrl } from "utils/FileUtils";
import WaveDivider from "./components/WaveDivider";
import TarotCarousel from "./components/TarotCarousel";
import { useState } from "react";
import TarotCard from "./components/TarotCard";
import SanButton from "components/SanButton";
import { setLoading } from "store/billSlice";
export type TarotExperienceProps = {};

type SelectedCard = {
  id: number;
  title: string;
  image: string;
};

export default function TarotExperience(props: TarotExperienceProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { topicType = "love" } = state || {};
  const isLogin = useAppSelector((root) => root.auth.isLogin);

  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const topics = {
    love: {
      id: 1,
      title: t("love"),
      image: s3ImageUrl("love_header.png"),
      bgColor: "#F5B1AD",
      type: "love",
      scale: "",
      secondMarginTop: 70,
      firstMarginTop: 20,
    },
    career: {
      id: 2,
      title: t("career"),
      image: s3ImageUrl("career_header.png"),
      bgColor: "#C4E9F4",
      type: "career",
      scale: "scale-[2]",
      secondMarginTop: 80,
      firstMarginTop: 80,
    },
    finance: {
      id: 3,
      title: t("finance"),
      image: s3ImageUrl("finance_header.png"),
      bgColor: "#FFE2BA",
      type: "finance",
      scale: "",
      secondMarginTop: 60,
      firstMarginTop: 20,
    },
  };

  const selectedCardTitleByIndex = {
    0: t("present"),
    1: t("future"),
    2: t("advice"),
  };

  const generatedCards = [
    {
      id: 1,
      image: "card_demo_0.png",
    },
    {
      id: 2,
      image: "card_demo_1.png",
    },
    {
      id: 3,
      image: "card_demo_2.png",
    },
  ];

  const [selectedCards, setSelectedCards] = useState<SelectedCard[]>([]);

  const selectedTopic = topicType ? topics[topicType as string] : topics.love;

  const handleCardClick = () => {
    const nextIndex = selectedCards.length;
    nextIndex < 3 &&
      setSelectedCards([
        ...selectedCards,
        {
          id: nextIndex,
          title: selectedCardTitleByIndex[nextIndex],
          image: generatedCards[nextIndex].image,
        },
      ]);
  };

  return (
    <div
      style={{ backgroundColor: selectedTopic.bgColor }}
      className="flex h-screen flex-col items-center bg-[#210909]"
    >
      {/* Section 1 */}
      <div className="relative flex w-full flex-col items-center">
        <section
          className="z-0 flex h-[300px] w-full max-w-screen-md flex-col items-center overflow-hidden transition-all duration-200 ease-in-out md:h-[500px]"
          style={{ height: selectedCards.length > 0 ? "200px" : "300px" }}
        >
          <PageHeader transparent backColor="#131313" />
          <img
            src={selectedTopic.image}
            alt=""
            style={{
              marginTop:
                selectedCards.length > 0
                  ? selectedTopic.firstMarginTop
                  : selectedTopic.secondMarginTop,
            }}
            className={`h-auto w-2/3 cursor-pointer object-contain md:mt-36 ${selectedTopic.scale} transition-all duration-200 ease-in-out `}
          />
          <Gap size="S" />
        </section>
        <WaveDivider />
      </div>

      {/* Section 2 */}
      <section className="flex h-full w-full flex-col items-center justify-center bg-[#210909]">
        <div className="flex h-full w-full max-w-screen-md flex-col items-center">
          <span
            style={{ color: selectedTopic.bgColor }}
            className={`mt-10 font-space text-[40px] font-bold leading-[56px]`}
          >
            {selectedTopic.title}
          </span>
          <Gap size="M" />
          <Gap size="M" />
          <div>
            {/* Render selected card */}
            {selectedCards.length > 0 && (
              <div className="flex flex-row gap-6">
                {selectedCards.map((card, index) => (
                  <div className="flex flex-col items-center justify-start">
                    <TarotCard
                      className="h-[150px]"
                      cardImage={card.image}
                      key={`selected-card-${index}`}
                      imageClassName="h-[144px]"
                    />
                    <Gap size="XXS" />
                    <span className="font-inter text-12px font-normal text-tertiary">
                      {card.title}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Gap size="XS" />
          <span className="font-inter text-12px font-normal text-tertiary">{`${t("selectCard")}`}</span>
          <Gap size="XS" />
          <img
            src={s3ImageUrl("tarot_divide.png")}
            className="w-3/4 md:w-3/5"
            alt=""
          />
          <Gap size="M" />
          <div className="flex w-full max-w-screen-md flex-col items-center md:pl-14">
            <TarotCarousel onCardClick={handleCardClick} />
          </div>
          <div className="flex w-full flex-col items-center px-4">
            {selectedCards.length === 3 ? (
              <SanButton
                type="outline"
                onClick={() => {
                  setIsLoadingResult(true);
                  setTimeout(() => {
                    setIsLoadingResult(false);
                    navigate("/tarot-result");
                  }, 2500);
                }}
                loading={isLoadingResult}
                className="bg-transparent px-4"
                title={t("tarotResult")}
              />
            ) : (
              <div className="flex w-full flex-col items-center">
                <img
                  src={s3ImageUrl("swipe_guide.png")}
                  className="w-1/4"
                  alt=""
                />
                <Gap size="XXS" />
                <span className="font-inter text-12px font-normal text-tertiary">{`${t("swipeToMove")}`}</span>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
