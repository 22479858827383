import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";

import { EffectCards } from "swiper/modules";
import TarotCard from "./TarotCard";

export type TarotCarouselProps = {
  onCardClick: () => void;
};

export default function TarotCarousel(props: TarotCarouselProps) {
  const { onCardClick = () => null } = props;
  return (
    <div className="w-full">
      <Swiper
        effect={"cards"}
        grabCursor={true}
        spaceBetween={8}
        centeredSlides={true}
        watchSlidesProgress={true}
        slidesPerView={5}
        initialSlide={3}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {Array.from({ length: 10 }).map((_, index) => (
          <SwiperSlide>
            {({ isActive }) => (
              <div>
                <TarotCard
                  onCardClick={onCardClick}
                  isActive={isActive}
                  key={`tarot-card-${index}`}
                />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
