import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import IconGame from "../assets/icons/icon-game";
import { useAppDispatch, useAppSelector } from "../store/hook";
import SanButton from "./SanButton";
import ChevronRight from "assets/icons/chevron-right";
import { cartItemsTotalPrice } from "contexts/cart/cart.reducer";
import { formatCurrency } from "utils/ConcurencyUtils";
import { useLanguage } from "hooks/useLanguage";
import { useCreateOrderMutation } from "store/api/consumerApi";
import { v4 as uuidv4 } from "uuid";
import { Bill, Order } from "store/type";
import { createBill } from "store/billSlice";
import { s3ImageUrl } from "utils/FileUtils";
import { withSnackbar, WithSnackbarProps } from "hoc/withSnackbar";

type BottomTabBarProps = {
  menus: {
    id: number;
    href: string;
    title: string;
    showHeader: boolean;
    icon: (props: any) => JSX.Element;
    showBadge: boolean;
  }[];
};

const BottomTabBar = ({
  menus,
  showSnackbar = () => null,
}: BottomTabBarProps & WithSnackbarProps) => {
  const location = useLocation();
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const totalPrice = cartItemsTotalPrice(currentOrder?.items);
  const orderItem = currentOrder?.items.reduce((acc, current) => {
    return current.quantity + acc;
  }, 0);

  const { language, t } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [
    createOrder,
    { isLoading: isCreatingOrder, isSuccess: isCreateOrderSuccess },
  ] = useCreateOrderMutation();
  const showCheckout = async () => {
    const orderWithDedupeId = {
      ...currentOrder,
      dedup_id: uuidv4(),
    };
    const createOrderResponse = await createOrder({
      order: orderWithDedupeId as Order,
      locale: language,
    });
    const bill = createOrderResponse.data;
    if (isCreateOrderSuccess && bill) {
      dispatch(createBill(bill as Bill));
      navigate("/checkout");
    } else {
      showSnackbar({
        message: t("somethingWentWrong"),
      });
    }
  };

  const shouldShowPaidButton = location.pathname === "/orders";

  return (
    <div className="fixed bottom-4 left-2 right-2 flex h-fit flex-col items-center justify-center gap-2">
      {shouldShowPaidButton && (currentOrder?.items?.length || 0) > 0 && (
        <div className="flex w-full min-w-[350px] max-w-screen-md flex-row items-center justify-center gap-2 px-4">
          <SanButton type="outline" title={formatCurrency(totalPrice || 0)} />
          <SanButton
            title={t("payment")}
            onClick={showCheckout}
            loading={isCreatingOrder}
            RightIcon={ChevronRight}
          />
        </div>
      )}
      <div className="flex flex-row items-center justify-center gap-2">
        <div className="center flex w-[302px] gap-[8px] rounded-[40px] bg-white px-1 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
          {menus.map((menu) => (
            <NavBarItem
              key={`nav-item-${menu.id}`}
              badgeCount={orderItem}
              to={menu.href}
              label={menu.title}
              IconComponent={menu.icon}
              showBadge={menu?.showBadge}
              isActive={location.pathname === menu.href}
            />
          ))}
        </div>
        <div
          onClick={() => navigate("/welcome-to-tarot")}
          className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-[48px] bg-primary shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
        >
          <img src={s3ImageUrl("magic-ball.png")} className="h-6 w-6" alt="" />
        </div>
      </div>
    </div>
  );
};

type NavItemProps = {
  to: string;
  label: string;
  IconComponent: React.FC<{ className?: string; color: string }>;
  badgeCount?: number;
  showBadge?: boolean;
  isActive?: boolean;
};

const NavBarItem: React.FC<NavItemProps> = ({
  to,
  label,
  IconComponent,
  badgeCount = 0,
  showBadge = false,
  isActive = false,
}) => {
  const itemColor = isActive ? "#8D3D3A" : "#626262";

  return (
    <NavLink
      to={to}
      className={`relative flex w-fit flex-col items-center py-1 transition-all duration-200 ease-in-out`}
    >
      <div
        className={`flex flex-row items-center justify-center rounded-[40px] px-[8px] py-2 transition-all duration-200 ease-in-out
        ${isActive ? "w-[102px] border-[1px] border-primary" : "w-14 border-transparent"}
      `}
      >
        <IconComponent color={itemColor} />
        {badgeCount > 0 && showBadge && !isActive && (
          <div
            className={`absolute right-2 top-2 flex h-[14px] w-[14px] flex-row items-center justify-center rounded-full bg-brand-1 pt-[1px]`}
          >
            <p className={`text-[10px] text-white`}>{badgeCount}</p>
          </div>
        )}
        <span
          className={`ml-1 whitespace-nowrap pt-[2px] transition-all duration-200 ease-in-out
          ${isActive ? "translate-y-0 text-primary opacity-100" : "hidden translate-y-2 opacity-0"}`}
        >
          {label}
        </span>
      </div>
    </NavLink>
  );
};

export default withSnackbar(BottomTabBar);
