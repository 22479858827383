import CloseIcon from "assets/icons/close";
import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { s3ImageUrl } from "utils/FileUtils";
import "./tarotResult.css";
import { useState } from "react";

export type TarotResultProps = {};

export default function TarotResult(props: TarotResultProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  const generatedCards = [
    {
      id: 1,
      image: "card_demo_0.png",
      title: "Tên lá bài 1",
      dink: {
        name: "Cafe",
        image: "recommend_drink_demo.png",
      },
    },
    {
      id: 2,
      image: "card_demo_1.png",
      title: "Tên lá bài 2",
      dink: {
        name: "Caramel Machiato",
        image: "recommend_drink_demo_1.png",
      },
    },
    {
      id: 3,
      image: "card_demo_2.png",
      title: "Tên lá bài 3",
      dink: {
        name: "Yellow Tea",
        image: "recommend_drink_demo_2.png",
      },
    },
  ];

  const selectedCardTitleByIndex = {
    0: t("present"),
    1: t("future"),
    2: t("advice"),
  };

  const meaning = `Lorem ipsum dolor sit amet. Consectetuer adipiscing elit. 
  Sed diam nonummy nibh euismod tincidunt ut. Laoreet dolore magna aliquam erat volutpat.
  Ut wisi enim ad minim veniam. Quis nostrud exerci tation ullamcorper suscipitt`;

  const meaningInArrays = meaning.split(".");

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-[#210909]">
      <div className="flex h-full w-full max-w-screen-md flex-col">
        <PageHeader
          titleColor="#fff"
          title={generatedCards[activeIndex].title}
          IconLeft={() => (
            <div>
              <div
                onClick={() => {
                  navigate("/");
                }}
                className="flex h-9 w-9 cursor-pointer items-center justify-center"
              >
                <CloseIcon color={"#FFF"} />
              </div>
            </div>
          )}
          transparent
          backColor="#fff"
        />
        <Gap size="M1" />
        <Swiper
          onActiveIndexChange={(e) => setActiveIndex(e.activeIndex)}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={16}
        >
          {generatedCards.map((card, index) => (
            <SwiperSlide>
              {({ isActive }) => (
                <div>
                  <img
                    src={s3ImageUrl(card.image)}
                    className={`${isActive ? "opacity-100" : "opacity-50"} transition-all duration-300 ease-in-out`}
                    alt=""
                  />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <Gap size="M" />
        <span className="text-center font-inter text-16px font-semibold text-brand-3">
          {selectedCardTitleByIndex[activeIndex]}
        </span>
        <div className="px-4 text-center">
          {meaningInArrays.map((item, index) => (
            <span
              key={index}
              className={`text-center font-inter text-12px font-semibold text-brand-3 ${index === activeIndex * 2 || index === activeIndex * 2 + 1 ? "" : "opacity-50"}`}
            >
              {`${item}.`}
            </span>
          ))}
        </div>
        <Gap size="M1" />
        <Gap size="XS" />
        {/* Drink recommend */}
        <div className="flex  w-full flex-col items-center justify-center px-4">
          <div className="relative flex h-[96px] w-full flex-row items-center justify-end rounded-2xl bg-[#CA412E] p-4">
            <div className="flex w-full flex-col">
              <span className="font-inter text-11px text-divide">
                {t("recommendDrink")}
              </span>
              <span className="font-space text-16px font-semibold text-brand-shape-3">{`${generatedCards[activeIndex].dink.name}`}</span>
              <span className="font-inter text-12px font-semibold text-divide">
                {t("addToCart")}
              </span>
            </div>
            <img
              src={s3ImageUrl(generatedCards[activeIndex].dink.image)}
              alt=""
              className="absolute bottom-4 right-4 h-auto w-16 object-bottom"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-screen-md flex-col items-center justify-start"></div>
    </div>
  );
}
